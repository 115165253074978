import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import type {
    Options,
    StripeData,
    FormSubmissionData,
    ProgressData,
    PhotoUploadData,
    PingStartData,
    SendStripeTokenResult,
    StartAdyenSessionResponse,
    StartAdyenSessionBody,
    AdditionalAdyenDetailsBody
} from './types/guestPortalApi.types';



export const guestPortalApi = createApi({
    reducerPath: 'guestPortalApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${config.autohostApiEndpoint}/guestportal` }),
    endpoints: (builder) => ({
        getReservation: builder.query<string, [string, Options]>({
            query: (id, options: Options = {}) => {
                const query = Object.keys(options)
                    .map((k) => `${k}=${options[k]}`)
                    .join('&');
                return `/reservation/${id}?${query}`;
            },
        }),
        getCheckin: builder.query<string, string>({
            query: (id) => `/checkin/${id}`,
        }),
        pingStart: builder.query<PingStartData, string>({
            query: (id) => {
                // @ts-ignore
                const fp = global.__ahpfp;
                // @ts-ignore
                const visitorId = global.__ahpfppro;
                // @ts-ignore
                const requestId = global.__ahpfpprorid;
                return `/pingstart/${id}${fp ? `?version=${fp}&session=${visitorId}&request=${requestId}` : ''}`;
            },
        }),
        sendStripeToken: builder.mutation<SendStripeTokenResult, StripeData>({
            query: ({ id, data, step }) => ({
                url: step ? `/stripe/${id}?step=${step}` : `/stripe/${id}`,
                method: 'POST',
                body: data,
            }),
        }),
        sendChargeRequest: builder.mutation<string, StripeData>({
            query: ({ id, data, step }) => ({
                url: step ? `/extraCharges/${id}?step=${step}` : `/extraCharges/${id}`,
                method: 'POST',
                body: data,
            }),
        }),
        submitForm: builder.mutation<string, FormSubmissionData>({
            query: ({ reservationId, data, query = {} }) => {
                const queryString = Object.keys(query)
                    .map((k) => `${k}=${query[k]}`)
                    .join('&');
                return {
                    url: `/finished/${reservationId}?${queryString}`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        saveProgress: builder.mutation<string, ProgressData>({
            query: ({ reservationId, lastStep, data }) => ({
                url: `/saveProgress/${reservationId}?last_step=${lastStep}`,
                method: 'POST',
                body: data,
            }),
        }),
        uploadPhoto: builder.mutation<string, PhotoUploadData>({
            query: ({ reservationId, imageType, b64Image, query }) => {
                const q = query
                    ? `?${Object.keys(query)
                        .map((k) => `${k}=${query[k]}`)
                        .join('&')}`
                    : '';
                return {
                    url: `/uploadPhoto/${reservationId}${q}`,
                    method: 'POST',
                    body: { type: imageType, image: b64Image },
                };
            },
        }),
        startAdyenSession: builder.query<StartAdyenSessionResponse, StartAdyenSessionBody>({
            query: (body) => {
                return {
                    url: '/deposits/adyen/session',
                    method: 'POST',
                    body
                }
            },
        }),
        setupAdyenToken: builder.mutation({
            query: (body) => {
                return {
                    url: '/deposits/adyen/token',
                    method: 'POST',
                    body
                }
            }
        }),
        sendAdditionalAdyenDetails: builder.mutation({
            query: (body: AdditionalAdyenDetailsBody) => {
                return {
                    url: '/deposits/adyen/paymentDetails',
                    method: 'POST',
                    body
                }
            }
        })
    }),
});


export const useGetReservationQuery = guestPortalApi.endpoints.getReservation.useQuery;
export const useGetCheckinQuery = guestPortalApi.endpoints.getCheckin.useQuery;
export const usePingStartQuery = guestPortalApi.endpoints.pingStart.useQuery;
export const useSendStripeTokenMutation = guestPortalApi.endpoints.sendStripeToken.useMutation;
export const useSendChargeRequestMutation = guestPortalApi.endpoints.sendChargeRequest.useMutation;
export const useSubmitFormMutation = guestPortalApi.endpoints.submitForm.useMutation;
export const useSaveProgressMutation = guestPortalApi.endpoints.saveProgress.useMutation;
export const useUploadPhotoMutation = guestPortalApi.endpoints.uploadPhoto.useMutation;
export const useStartAdyenSessionQuery = guestPortalApi.endpoints.startAdyenSession.useQuery
export const useSetupAdyenToken = guestPortalApi.endpoints.setupAdyenToken.useMutation
export const useSendAdditionalAdyenDetails = guestPortalApi.endpoints.sendAdditionalAdyenDetails.useMutation
